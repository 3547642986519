<template>
  <div class="saleToday">
    <div class="saleBtnWrap">
      <!-- <button class="salebtn" type="button">{{floorName}}</button>
      <p class="saleSlogan">实惠好物&nbsp;&nbsp;等你来抢</p> -->
      <div class="jrtm"></div>
    </div>
    <div class="salePart" :class="{ tile: saleList.length > 3 }">
      <div
        class="saleGoodsWrap"
        v-for="(goods, index1) in saleList"
        :key="'saleGoodsWrap' + index1"
      >
      <div
          v-for="(item, index2) in goods"
          :class="index2==0?'frontGoods':'backGoods'"
          :key="'saleGoods' + index2"
          @click="toLink(item.spuId)"
        >
          <img :src="item.imgUrl" alt="" class="goodsImg" />
          <div class="goodsPrize">
            <span class="newPrize">{{detailPrice(item.salePrice,100)}}</span>
            <span class="oldPrize" v-if="!!item.originalPrice">{{
              detailPrice(item.originalPrice,100)
            }}</span>
          </div>
        </div>
        <!-- <div class="frontGoods">

        </div>
        <div class="backGoods"></div> -->

        <!-- <div
          class="saleGoods"
          v-for="(item, index2) in goods"
          :key="'saleGoods' + index2"
          @click="toLink(item.spuId)"
        >
          <img :src="item.pictUrl" alt="" class="goodsImg" />
          <div class="goodsPrize">
            <span class="newPrize">{{ item.actualPrice }}</span>
            <span class="oldPrize" v-if="!!item.markingPrice">{{
              item.markingPrice
            }}</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "saleToday",
  data() {
    return {
      floorName: "",
      saleList: [],
      dataList: [
        {
          pictUrl:
            "http://dspcloud.spay365.com/a640d0d1f88920a1c82f20c20c456eb13b929c28.jpg",
          markingPrice: "15.9",
          actualPrice: "9.9",
          clickUrl: "",
          spuId: 74,
        },
        {
          pictUrl:
            "http://dspcloud.spay365.com/38f1c7de3eba2348e1f173aa6e8adabbf20832cd.png",
          markingPrice: "14.5",
          actualPrice: "9.9",
          clickUrl: "",
          spuId: 188,
        },
        {
          pictUrl:
            "http://dspcloud.spay365.com/c9f2894de449ab20fbf7217034398da76edb0e1c.jpg",
          markingPrice: "99.9",
          actualPrice: "59",
          clickUrl: "",
          spuId: 93,
        },
        {
          pictUrl:
            "http://dspcloud.spay365.com/9622cfbbd209dbff7a667cc24d57e0f672851409.jpg",
          markingPrice: "145",
          actualPrice: "99",
          clickUrl: "",
          spuId: 167,
        },
        {
          pictUrl:
            "http://dspcloud.spay365.com/a640d0d1f88920a1c82f20c20c456eb13b929c28.jpg",
          markingPrice: "15.9",
          actualPrice: "9.9",
          clickUrl: "",
          spuId: 74,
        },
        {
          pictUrl:
            "http://dspcloud.spay365.com/38f1c7de3eba2348e1f173aa6e8adabbf20832cd.png",
          markingPrice: "14.5",
          actualPrice: "9.9",
          clickUrl: "",
          spuId: 188,
        },
        {
          pictUrl:
            "http://dspcloud.spay365.com/c9f2894de449ab20fbf7217034398da76edb0e1c.jpg",
          markingPrice: "99.9",
          actualPrice: "59",
          clickUrl: "",
          spuId: 93,
        },
        {
          pictUrl:
            "http://dspcloud.spay365.com/9622cfbbd209dbff7a667cc24d57e0f672851409.jpg",
          markingPrice: "145",
          actualPrice: "99",
          clickUrl: "",
          spuId: 167,
        },
      ],
    };
  },
  created() {},
  beforeMount() {
    this.getFloors();
    // this.delData();
  },
  mounted() {},
  methods: {
    detailPrice(arg1,arg2) {
      console.log(arg1+'======'+arg2)
       var t1 = 0,
        t2 = 0,
        r1, r2;
        try {
          t1 = arg1.toString().split(".")[1].length
        } catch (e) {}
        try {
          t2 = arg2.toString().split(".")[1].length
        } catch (e) {}
        r1 = Number(arg1.toString().replace(".", ""));
        r2 = Number(arg2.toString().replace(".", ""));
        if (r2 == 0) {
          return Infinity;
        } else {
          return (r1 / r2) * Math.pow(10, t2 - t1);
        }
    },
    //  跳转热门商品
    toLink(spuId) {
      //  todo 跳转链接
      console.log(spuId);
      // window.location.href = url;
      this.$router.push({
        path: "/h/scm/mall/goodsDetail",
        query: {
          sceneNo: this.$route.query.scence,
          spuId: spuId,
          channel:localStorage.getItem('channel')
        },
      });
    },
    delData() {
      const count = 4; //  一行固定多少商品
      let saleList = [];
      this.dataList.forEach((item, index) => {
        if (index + 1 > count * 2) return; //  如果配置商品超过两行无视掉(一般不会有这种情况 以防外一)
        let curIndex = index % count;
        let goodsPart = saleList[curIndex] || [];
        goodsPart.push(item);
        saleList[curIndex] = goodsPart;
      });
      console.log(saleList)
      this.saleList = saleList;
    },
    // getFloors() {
    //   this.$get("/api/n/aid/taobaoke/floor/goods/list", {
    //     floorTypeId: 2,
    //   }).then((res) => {
    //     const { data, code } = res;
    //     if (code == "0") {
    //       const { floorName, goodsList } = data;
    //       this.floorName = floorName;
    //       const count = 4; //  一行固定多少商品
    //       let saleList = [];
    //       goodsList.forEach((item, index) => {
    //         if (index + 1 > count * 2) return; //  如果配置商品超过两行无视掉(一般不会有这种情况 以防外一)
    //         let curIndex = index % count;
    //         let goodsPart = saleList[curIndex] || [];
    //         goodsPart.push(item);
    //         saleList[curIndex] = goodsPart;
    //       });
    //       this.saleList = saleList;
    //     }
    //   });
    // },
    getFloors() {
      // for(let i=0;i<8;i++){
      //    this.request.get("/api/banner/index/info", {
      //   type:i,
      //   count:1,
      //   sceneNo:this.$route.query.scence,
      //   channelNo:this.$route.query.channel
      // })
      // }
      this.request.get("/api/banner/index/info", {
        type:3,
        count:1,
        sceneNo:this.$route.query.scence,
        channelNo:localStorage.getItem('channel')
      }).then((res) => {
        const { type, bannerProductBaseInfos } = res;
        if (type&&bannerProductBaseInfos) {
          // const { floorName, goodsList } = data;
          // this.floorName = floorName;
          const count = 4; //  一行固定多少商品
          let saleList = [];
          bannerProductBaseInfos.forEach((item, index) => {
            if (index + 1 > count * 2) return; //  如果配置商品超过两行无视掉(一般不会有这种情况 以防外一)
            let curIndex = index % count;
            let goodsPart = saleList[curIndex] || [];
            goodsPart.push(item);
            saleList[curIndex] = goodsPart;
          });
          this.saleList = saleList;
          console.log(this.saleList)
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.saleToday {
  .saleBtnWrap {
    overflow: hidden;
    // height: 1.24rem;
    // background: url(~@/assets/img/newindex/salebtnbg.png) center / auto 1.24rem
    //   no-repeat;
    height: 0.9rem;
    width: 7.5rem;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .salebtn {
      margin-top: 0.05rem;
      background: none;
      border: none;
      height: 0.65rem;
      font-size: 0.32rem;
      color: #fff;
      font-weight: bold;
    }
    .saleSlogan {
      margin-top: 0.06rem;
      height: 0.48rem;
      line-height: 0.48rem;
      color: #fff;
      font-size: 0.24rem;
      text-shadow: 0.03rem 0.03rem 0.04rem rgba($color: #ef0200, $alpha: 0.6);
    }
    .jrtm {
      width: 3.94rem;
      height: 0.5rem;
      background: url(~@/assets/newMall/jryh.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .salePart {
    width: 6.9rem;
    margin: 0 auto;
    display: flex;
    &.tile {
      justify-content: space-between;
    }
    &:not(.tile) {
      .saleGoodsWrap {
        margin-right: 0.16rem;
      }
    }
    .saleGoodsWrap {
      width: 1.6rem;
      height: 2.1rem;
      position: relative;
      position: relative;
      transition: 1s;
      transform-style: preserve-3d;
      animation: frontSaleGoodsAnimate 10s linear 0ms infinite;
    }
    .frontGoods {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      transform: rotateY(180deg);
      backface-visibility: hidden; /*两个元素的位置一致,把背面隐藏起来,背面的元素翻转过来*/
      top: 0;
      right: 0;
      overflow: hidden;
      background: #ff3e2f;
      border-radius: .16rem;
      .goodsImg {
        border-radius: 0.16rem;
        display: block;
        height: 1.6rem;
        width: 100%;
      }
      .goodsPrize {
        margin-top: 0.1rem;
        height: 0.24rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 0.1rem 0 0;
        color: #fff;
      }
      .newPrize {
        line-height: 0.28rem;
        font-size: 0.28rem;
        font-weight: bold;
        white-space: nowrap;
        letter-spacing: -0.04em;
        &::before {
          content: "￥";
          display: inline-block;
          transform: scale(0.7);
          transform-origin: right 0.24rem;
          letter-spacing: -0.06em;
        }
      }
      .oldPrize {
        flex: 1;
        font-size: 0.2rem;
        color: #ffaaa3;
        text-decoration: line-through;
        white-space: nowrap;
        line-height: 1;
        letter-spacing: -0.02rem;
        padding-bottom: 0.01rem;
        text-align: right;
        &::before {
          content: "￥";
          letter-spacing: -0.4em;
        }
      }
    }
    .backGoods {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      backface-visibility: hidden;
      top: 0;
      right: 0;
      overflow: hidden;
      background: #ff3e2f;
      border-radius: .16rem;
      .goodsImg {
        border-radius: 0.16rem;
        display: block;
        height: 1.6rem;
        width: 100%;
      }
      .goodsPrize {
        margin-top: 0.1rem;
        height: 0.24rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 0.1rem 0 0;
        color: #fff;
      }
      .newPrize {
        line-height: 0.28rem;
        font-size: 0.28rem;
        font-weight: bold;
        white-space: nowrap;
        letter-spacing: -0.04em;
        &::before {
          content: "￥";
          display: inline-block;
          transform: scale(0.7);
          transform-origin: right 0.24rem;
          letter-spacing: -0.06em;
        }
      }
      .oldPrize {
        flex: 1;
        font-size: 0.2rem;
        color: #ffaaa3;
        text-decoration: line-through;
        white-space: nowrap;
        line-height: 1;
        letter-spacing: -0.02rem;
        padding-bottom: 0.01rem;
        text-align: right;
        &::before {
          content: "￥";
          letter-spacing: -0.4em;
        }
      }
    }
    .saleGoods {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      background: #ff3e2f;
      // box-shadow: 0 0 0.2rem rgba($color: #f94444, $alpha: 0.65);
      border-radius: 0.16rem;
      .goodsImg {
        border-radius: 0.16rem;
        display: block;
        height: 1.6rem;
        width: 100%;
      }
      .goodsPrize {
        margin-top: 0.1rem;
        height: 0.24rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 0.1rem 0 0;
        color: #fff;
      }
      .newPrize {
        line-height: 0.28rem;
        font-size: 0.28rem;
        font-weight: bold;
        white-space: nowrap;
        letter-spacing: -0.04em;
        // &::before {
        //   content: "￥";
        //   display: inline-block;
        //   transform: scale(0.7);
        //   transform-origin: right 0.24rem;
        //   letter-spacing: -0.06em;
        // }
      }
      .oldPrize {
        flex: 1;
        font-size: 0.2rem;
        color: #ffaaa3;
        text-decoration: line-through;
        white-space: nowrap;
        line-height: 1;
        letter-spacing: -0.02rem;
        padding-bottom: 0.01rem;
        text-align: right;
        // &::before {
        //   content: "￥";
        //   letter-spacing: -0.4em;
        // }
      }
    }
  }
}

.frontSaleGoods {
  animation: frontSaleGoodsAnimate 10.2s linear 0ms infinite;
  .newPrize {
    animation: frontNewPrizeAnimate 10.2s linear 5.1s infinite;
  }
  .oldPrize {
    animation: frontOldPrizeAnimate 10.2s linear 5.1s infinite;
  }
}
.backSaleGoods {
  animation: backSaleGoodsAnimate 10.2s linear 0ms infinite;
  .newPrize {
    animation: backNewPrizeAnimate 10.2s linear 0s infinite;
  }
  .oldPrize {
    animation: backOldPrizeAnimate 10.2s linear 0s infinite;
  }
}
// @keyframes frontSaleGoodsAnimate {
//   0.00% {
//     transform: scaleX(1);
//     opacity: 1;
//   }
//   45.38% {
//     transform: scaleX(1);
//     opacity: 1;
//   }
//   48.19% {
//     transform: scaleX(0);
//     opacity: 0;
//   }
//   50.00% {
//     transform: scaleX(0);
//     opacity: 0;
//   }
//   95.38% {
//     transform: scaleX(0);
//     opacity: 0;
//   }
//   98.19% {
//     transform: scaleX(0);
//     opacity: 0;
//   }
//   100.00% {
//     transform: scaleX(1);
//     opacity: 1;
//   }
// }
@keyframes frontSaleGoodsAnimate {
  0.00% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  40% {
    transform: rotateY(0deg);
  }
  60% {
    transform: rotateY(180deg);
  }
  80% {
    transform: rotateY(180deg);
  }
  90% {
    transform: rotateY(180deg);
  }
  100.00% {
    transform: rotateY(0deg);
  }
}
@keyframes backSaleGoodsAnimate {
  0.00% {
    transform: scaleX(0);
    opacity: 0;
  }
  45.38% {
    transform: scaleX(0);
    opacity: 0;
  }
  48.19% {
    transform: scaleX(0);
    opacity: 0;
  }
  50.00% {
    transform: scaleX(1);
    opacity: 1;
  }
  95.38% {
    transform: scaleX(1);
    opacity: 1;
  }
  98.19% {
    transform: scaleX(0);
    opacity: 0;
  }
  100.00% {
    transform: scaleX(0);
    opacity: 0;
  }
}
@keyframes frontNewPrizeAnimate {
  50% {
    // transform: translateX(-150%);
    opacity: 0;
  }
  55% {
    // transform: translateX(0);
    opacity: 1;
  }
  95% {
    // transform: translateX(0);
    opacity: 1;
  }
}
@keyframes frontOldPrizeAnimate {
  50% {
    // transform: translateX(150%);
    opacity: 0;
  }
  55% {
    // transform: translateX(0);
    opacity: 1;
  }
  95% {
    // transform: translateX(0);
    opacity: 1;
  }
}
@keyframes backNewPrizeAnimate {
  50% {
    // transform: translateX(-150%);
    opacity: 0;
  }
  55% {
    // transform: translateX(0);
    opacity: 1;
  }
}
@keyframes backOldPrizeAnimate {
  50% {
    // transform: translateX(150%);
    opacity: 0;
  }
  55% {
    // transform: translateX(0);
    opacity: 1;
  }
}
</style>