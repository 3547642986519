<template>
  <div id="hotSale">
    <van-list
      class="goodsList"
      v-model="listIsLoading"
      :finished="loadFinised"
      @load="getHotGoodsList"
    >
      <!-- 空数据占位start -->
      <template v-if="!isListInit">
        <div class="aGoods loading" v-for="n in 2" :key="'aHotGoods' + n">
          <div class="goodsImg"></div>
          <p class="goodsName gapLoad">--</p>
          <div class="goodsInfo">
            <!-- <div><span class="returnSign gapHidden">--</span></div> -->
            <span class="saleSign gapHidden">专享折后价</span>
            <div class="goodsPrizeWrap gapLoad"></div>
          </div>
        </div>
      </template>
      <!-- 空数据占位end -->
      <div
        class="aGoods"
        v-for="item in hotList"
        :key="item.id"
        @click="toGoodsDetail(item)"
      >
        <div
          class="goodsImg"
          :class="{
            noImg: !item.spuSnapshotGallery || !item.spuSnapshotGallery.imgUrl,
          }"
        >
          <img
            :alt="item.name"
            v-if="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
            :src="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
          />
        </div>
        <p class="goodsName">{{ item.name }}</p>
        <div class="goodsInfo">
          <!-- <div><span class="returnSign">7天无理由退还</span></div> -->
          <span class="saleSign">专享折后价</span>
          <div class="goodsPrizeWrap">
            <span class="goodsPrize">￥{{ changeUnit(item.currentPrice) }}</span>
            <span class="oldPrize"
              ><s>￥{{changeUnit(item.originalPrice)  }}</s></span
            >
            <!-- <span class="buyNum">1776人已购买</span> -->
          </div>
        </div>
      </div>
    </van-list>
    <div style="height:1rem"></div>
  </div>
</template>

<script>
export default {
  name: "hotSale",
  data() {
    return {
      sceneNo:
        this.$route.query.scence ||
        this.$store.state.sceneNo ||
        sessionStorage.getItem("sceneNo") ||
        "", //  当前页面场景号
      curPage: 1, //  当前列表请求页数
      pageSize: 20, //  一次请求加载个数
      isListInit: false, //  是否初始化
      listIsLoading: false, //  列表是否正在加载
      loadFinised: true, //  列表全部加载完成标识(初始设置为true是手动加载列表，防止组件会自动加载列表)
      hotList: [], //  热门商品列表
      needLoading: true,
      virtualFlag: "",
    };
  },
  props: {
    categoryId: 0,
    tabStyle: false,
    listItemData: {},
  },
  watch: {
    // categoryId:function(newValue,oldValue){
    //     this.getGoodsList(newValue);
    //     this.curPage = 1;
    // },
    listItemData: function (newValue, oldValue) {
      console.log(newValue);
      this.getGoodsList(newValue);
      this.curPage = 1;
    },
  },
  created() {
    if (!!this.sceneNo) this.$store.commit("UPDATESCENCENO", this.sceneNo);
    // this.getHotGoodsList();
  },
  mounted() {
    window.addEventListener("scroll", this.windowScoll, false);
  },
  methods: {
    //  获取热门商品列表
    getHotGoodsList() {
      this.curPage++;
      this.global.getGoodsList(
        this.sceneNo,
        this.curPage,
        this.pageSize,
        (res) => {
          this.isListInit = true;
          this.listIsLoading = false;
          this.loadFinised = !res || res.length < this.pageSize; //  如果接口返回的数据小于分页数说明没有下一页了（接口没有返回总数 通过此方法来判断是否可以加载下一页）
          if (res && res.length) {
            this.hotList = this.hotList.concat(res);
            if (this.$props.tabStyle) {
              this.$nextTick(function () {
                document.getElementById("hotSale").scrollIntoView();
              });
            }
          }
        }
      );
    },
    getGoodsList(moduleData) {
      this.hotList = [];
      const { id, virtualFlag } = moduleData;
      this.request
        .get("/api/scm/sale/scene/spu/list", {
          sceneNo: this.sceneNo,
          page: "1",
          size: "10",
          categoryId: id,
        })
        .then((res) => {
          console.log(res);
          this.isListInit = true;
          this.listIsLoading = false;
          this.virtualFlag = virtualFlag;
          this.loadFinised = !res || res.length < this.pageSize; //  如果接口返回的数据小于分页数说明没有下一页了（接口没有返回总数 通过此方法来判断是否可以加载下一页）
          if (res && res.length) {
            this.hotList = this.hotList.concat(res);
            if (this.$props.tabStyle) {
              this.$nextTick(function () {
                document.getElementById("hotSale").scrollIntoView();
              });
            }
          }
        });
    },
    getMoreGoodsList() {
      this.curPage++;
      this.request
        .get("/api/scm/sale/scene/spu/list", {
          sceneNo: this.sceneNo,
          page: this.curPage,
          size: "10",
          categoryId: this.$props.categoryId,
        })
        .then((res) => {
          console.log(res);
          this.isListInit = true;
          this.listIsLoading = false;
          this.loadFinised = !res || res.length < this.pageSize; //  如果接口返回的数据小于分页数说明没有下一页了（接口没有返回总数 通过此方法来判断是否可以加载下一页）
          if (res && res.length) {
            this.hotList = this.hotList.concat(res);
          }
        });
    },
    //  跳转商品详情
    toGoodsDetail(item) {
      if (this.virtualFlag == "0") {
        //实物商品
        this.$router.push({
          path: "/h/scm/mall/goodsDetail",
          query: {
            sceneNo: this.sceneNo,
            channel: localStorage.getItem("channel"),
            spuId: item.spuId,
          },
        });
      } else if (this.virtualFlag == "1") {
        //虚拟商品/h/scm/virtual/goodsDetail
        this.$router.push({
          path: "/h/scm/virtual/goodsDetail",
          query: {
            sceneNo: this.sceneNo,
            id: item.spuId,
            channel: localStorage.getItem("channel"),
          },
        });
      }
    },
    //  跳转订单
    toOrderList() {
      this.$router.push({
        path: "/h/scm/order/orderList",
        query: {
          from: "hotSale",
          sceneNo: this.$route.query.scence,
        },
      });
    },
    windowScoll() {
      setTimeout(() => {
        var scrollHight =
          document.documentElement.scrollTop || document.body.scrollTop; //滚动条高度
        var elementHeight =
          document.scrollHeight || document.documentElement.scrollHeight; //文档高度
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight; //视口高度
        if (document.querySelector(".aGoods") == undefined) {
          return;
        }
        console.log(
          scrollHight + "======" + windowHeight + "=====" + elementHeight
        );
        var itemHeight = document.querySelector(".aGoods").offsetHeight;
        console.log(itemHeight);
        if (scrollHight + windowHeight > elementHeight - itemHeight / 3) {
          if (this.needLoading) {
            this.needLoading = false;
            this.getMoreGoodsList();
            setTimeout(() => {
              this.needLoading = true;
            }, 1000);
          }
        }
      }, 1000);
    },
    changeUnit(fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.delDot(num);
      return num;
    },
    delDot(num) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(num * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
  },
};
</script>

<style lang="scss" scoped>
.topBanner {
  height: 235px;
  position: relative;
  .orderLink {
    position: absolute;
    background: linear-gradient(to bottom, #fff6e4, #f4cc9f);
    top: 40px;
    right: 0;
    padding: 0 20px;
    height: 44px;
    display: block;
    border-radius: 44px 0 0 44px;
    color: #de4a34;
    font-size: 24px;
    box-shadow: 0 7px 10px rgba(149, 38, 28, 0.8);
  }
}
.hotGoodsWrap {
  background: #fff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  margin: 0 auto 30px;
  width: 690px;
  padding-bottom: 40px;
  .title {
    text-align: center;
    line-height: 100px;
    font-size: 34px;
    font-weight: bold;
    &::before,
    &::after {
      content: "";
      display: inline-block;
      height: 28px;
      width: 50px;
    }
    &::before {
      background: url(~@/assets/mall/saletitle-left.png) center center / auto
        100% no-repeat;
      margin-right: 20px;
    }
    &::after {
      background: url(~@/assets/mall/saletitle-right.png) center center / auto
        100% no-repeat;
      margin-left: 20px;
    }
  }
  .topGoods {
    padding: 5px 0 0 25px;
    font-size: 0;
  }
  .aGoods {
    width: 200px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    .goodsImg {
      height: 200px;
      border-radius: 10px;
      margin-bottom: 15px;
      position: relative;
      overflow: hidden;
      .buyNum {
        display: block;
        line-height: 40px;
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 0 0 0 10px;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px;
        padding: 0 12px;
      }
    }
    .goodsName {
      height: 36px;
      line-height: 36px;
      font-size: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
    }
    .goodsPrize {
      line-height: 30px;
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      color: #ea3d39;
      margin-top: 15px;
    }
    & + .aGoods {
      margin-left: 20px;
    }
  }
}
.goodsList {
  width: 690px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .aGoods:nth-of-type(2n) {
    margin-right: 0px;
  }
  .aGoods {
    width: 330px;
    margin-right: 30px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 12px;
    .goodsImg {
      height: 335px;
      position: relative;
    }
    .goodsName {
      margin: 10px 10px 10px 20px;
      height: 38px;
      line-height: 38px;
      font-size: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .goodsInfo {
      padding: 0 20px 20px;
      font-size: 0;
      .returnSign {
        line-height: 38px;
        height: 38px;
        font-size: 20px;
        color: #888;
        background: #f2f4f5;
        border-radius: 5px;
        display: inline-block;
        padding: 0 15px;
        margin-bottom: 12px;
        &::after {
          content: "";
          display: block;
        }
      }
      .goodsPrizeWrap {
        margin-top: 10px;
        line-height: 34px;
        min-height: 34px;
        overflow: hidden;
        clear: both;
      }
      .goodsPrize {
        font-size: 30px;
        font-weight: bold;
        color: #ea3d39;
        float: left;
      }
      .oldPrize {
        font-size: 30px;
        color: #999;
        float: right;
      }
      .buyNum {
        font-size: 22px;
        color: #999;
        float: right;
      }
    }
  }
}
</style>