<template>
  <div class="adTipWrap">
    <div class="adItem">
      <a :href="metaData.url"><img :src="metaData.imgUrls" alt="" /></a>
      <div class="fork" @click="closeTip"></div>
    </div>
  </div>
</template>
<style scoped lang='scss'>
.adTipWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  .adItem {
    width: 6.9rem;
    margin: 0 auto;
    border-radius: 0.05rem;
    a {
      display: block;
      width: 100%;
      img {
        display: block;
        width: 100%;
      }
    }
    .fork{
        width: 0.8rem;
        height: 0.8rem;
        background: url(~@/assets/newMall/fork.png) no-repeat;
        background-size: 100% 100%;
        margin:0 auto;
        margin-top: 0.1rem;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {};
  },
  props: {
    metaData: "",
  },
  methods:{
      closeTip(){
          this.$emit('closeAdTip')
      }
  }
};
</script>