<template>
  <div class="wrap">
    <div class="suspendBox" v-if="suspendData">
      <a :href="suspendData.url">
        <img :src="suspendData.imgUrls" alt="">
      </a>
    </div>
    <div ref="topHeader" class="headerBg">
      <swiper />
      <sale />
      <selectedGoods />
      <!-- <button type="button" class="orderLink" @click="toOrderList">
        我的订单
      </button> -->
    </div>
    <div ref="tab" class="top">
      <div class="tabModule">
        <ul class="material" ref="material">
          <li
            v-for="(item, index) in tabList"
            :key="index"
            v-html="item.name"
            :class="index == '0' ? 'sel' : ''"
            @click="searchMaterial(item, index)"
          ></li>
        </ul>
      </div>
    </div>
    <div ref="materialElement" class="shopModuleElement">
      <shopModule
        :shopData="listData"
        :categoryId="categoryId"
        :listItemData="listItemData"
        :tabStyle="tabStyle"
      />
    </div>
    <adTip :metaData='adTipDate' v-if="adTipShow" @closeAdTip='adTipShow=false' />
  </div>
</template>
<script>
import swiper from "./compment/swiper";
import sale from "./compment/saleToday";
import selectedGoods from "./compment/selectedGoods";
import shopModule from "./compment/shopModule";
import adTip from "./compment/adTip.vue";
export default {
  name: "newIndex",
  props: {},
  data() {
    return {
      tabList: [],
      listData: [],
      categoryId: "",
      listItemData: {},
      tabStyle: false, //true为fixed false为文本流
      mobile: this.$route.query.mobile,
      adTipDate:null,
      adTipShow:false,
      suspendData:null
    };
  },
  components: {
    swiper,
    sale,
    selectedGoods,
    shopModule,
    adTip,
  },
  created() {
    if (this.$route.query.channel) {
      localStorage.setItem("channel", this.$route.query.channel);
    }
  },
  mounted() {
    this.judgeSignTip();
    window.addEventListener("scroll", this.topScoll);
  },
  beforeMount() {
    this.getTabList();
    this.getSuspendData();
    // this.getUserData();
    if (this.mobile) {
      localStorage.setItem("channel", this.$route.query.channel);
      this.getUserData();
    }
  },
  beforeDestroy() {},
  methods: {
    getUserData() {
      localStorage.setItem('phoneNum',this.mobile);
      this.request
        .get(
          "/api/scm/user/address/getUserMobile?mobile=" +
            encodeURIComponent(this.mobile),
          {}
        )
        .then((res) => {
          if (res) {
            localStorage.setItem("userNumber", res);
          }
        });
    },
    getTabList() {
      this.request
        .get("/api/scm/product/category/list", {
          isLeaf: "1",
          page: "1",
          size: "6",
        })
        .then((res) => {
          console.log(res);
          this.tabList = res;

          var itemData = res[0];
          this.getShopList(itemData);
        });
    },
    searchMaterial(item, ind) {
      var lis = this.$refs.material.children;
      for (var i = 0; i < lis.length; i++) {
        lis[i].className = "";
      }
      lis[ind].className = "sel";
      this.getShopList(item);
    },
    getShopList(itemData) {
      ///api/scm/sale/scene/spu/list?sceneNo=RmAWTzryc&page=1&size=20&categoryId=2
      console.log(this.$route.query.scence);
      const { id } = itemData;
      this.listItemData = itemData;
      this.categoryId = id;
      if (this.$refs.tab.style.position == "fixed") {
        this.tabStyle = true;
      } else {
        this.tabStyle = false;
      }
      // this.request.get('/api/scm/sale/scene/spu/list',
      // {sceneNo:this.$route.query.scence,page:'1',size:'20',categoryId:id}).then(res =>{
      //   console.log(res)
      //   this.listData = res;
      // })
    },
    topScoll() {
      var scrollHight =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.$refs.topHeader == undefined) {
        return;
      }
      var height = this.$refs.topHeader.offsetHeight;
      var tab = this.$refs.tab;
      if (scrollHight > height) {
        // this.needComponent = 'smallTop'
        tab.style.position = "fixed";
        tab.style.background = "#fff";
      } else {
        tab.style.position = "";
        tab.style.background = "";
      }
    },
    toOrderList() {
      this.$router.push({
        path: "/h/scm/order/orderList",
        query: {
          from: "hotSale",
          sceneNo: this.$route.query.scence,
          channel: this.$route.query.channel,
        },
      });
    },
    judgeTimeDay() {
      var localDay = localStorage.getItem("localDay");
      if (localDay == null) {
        var d = new Date();
        var nowDay =
          String(d.getFullYear()) + String(d.getMonth()) + String(d.getDate());
        console.log(nowDay);
        localStorage.setItem("localDay", nowDay);
      }
      var t = new Date();
      var nowEnterDay =
        String(t.getFullYear()) + String(t.getMonth()) + String(t.getDate());
      // console.log(nowEnterDay+'==============')
      if (localDay == nowEnterDay) {
        localStorage.setItem("localDay", nowEnterDay);
        return true;
      } else {
        localStorage.setItem("localDay", nowEnterDay);
        return false;
      }
    },
    judgeSignTip() {
      var clickState = localStorage.getItem("clickState");
      var isToday = this.judgeTimeDay();
      clickState == null ? 0 : clickState; //0没弹过 1已弹
      if (isToday) {
        // console.log('是当天,判断当天是否弹过弹窗')
        if (clickState == "0") {
           this.getAdTip();
          localStorage.setItem("clickState", "1");
        } else {
          
        }
      } else {
         this.getAdTip();
        localStorage.setItem("clickState", "1");
      }
    },
    getAdTip() {
      this.request
        .get("/api/banner/index/info", {
          type: 2,
          count: 1,
          sceneNo: this.$route.query.scence,
          channelNo: localStorage.getItem("channel"),
        })
        .then((res) => {
          console.log(res);
          const {bannerBaseInfos} = res;
          if(bannerBaseInfos.length){
            this.adTipDate = bannerBaseInfos[0];
            this.adTipShow = true;
          }
        });
    },
    getSuspendData(){
       this.request
        .get("/api/banner/index/info", {
          type: 1,
          count: 1,
          sceneNo: this.$route.query.scence,
          channelNo: localStorage.getItem("channel"),
        })
        .then((res) => {
          console.log(res);
          const {bannerBaseInfos} = res;
          if(bannerBaseInfos.length){
            this.suspendData = bannerBaseInfos[0];
          }
        });
    }
  },
};
</script>
<style scoped lang='scss'>
.wrap {
  width: 7.5rem;
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: 0.38rem;
  position: relative;
  background: #f3f4f6;
}
.suspendBox{
  width: 0.8rem;
  height: 0.8rem;
  position: fixed;
  right: 0;
  top: 8rem;
  z-index: 9998;
  a{
    display: block;
    width: 100%;
    img{
      display: block;
      width: 100%;
    }
  }
}
.headerBg {
  width: 7.5rem;
  height: 9.4rem;
  background: url(~@/assets/newMall/bg.png) no-repeat;
  background-size: 100% auto;
  position: relative;
}
// .wrap /deep/ .module {
//   margin-top: 0;
// }
.orderLink {
  position: absolute;
  background: linear-gradient(to bottom, #fff6e4, #f4cc9f);
  top: 1.4rem;
  right: 0;
  padding: 0 0.2rem;
  height: 0.44rem;
  display: block;
  border-radius: 44px 0 0 44px;
  color: #de4a34;
  font-size: 24px;
  box-shadow: 0 7px 10px rgba(149, 38, 28, 0.8);
  z-index: 99999;
}
.bottomTips {
  line-height: 0.38rem;
  height: 0.38rem;
  background: #fff;
  font-size: 0.2rem;
  text-align: center;
  color: #bfbfbf;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.top {
  width: 7.5rem;
  /* height: 3rem; */
  z-index: 999;
  top: 0;
  left: 0;
  overflow: hidden;
}
.absoluteHeader {
  position: absolute;
  left: -100;
  top: -100;
  z-index: -100;
}
.tabModule {
  width: 6.9rem;
  margin-left: 0.3rem;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
}
.material {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.material li {
  height: 0.5rem;
  color: #333333;
  font-size: 0.28rem;
  text-align: center;
  line-height: 0.5rem;
  padding: 0 0.2rem;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}
.material li:nth-of-type(1) {
  margin-left: 0;
}
.material .sel {
  color: red;
}
.material .sel::before {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0rem;
  height: 0.06rem;
  border-radius: 0.06rem;
  position: absolute;
  background: #ff674e;
  z-index: -1;
}
.shopModuleElement {
  width: 7.5rem;
  min-height: 100vh;
}
</style>