<template>
  <div class="selectedGoods">
    <div class="selectedWrap">
      <p class="title">
        每日精选
        <img
          src="@/assets/newMall/selectedTitle1.png"
          alt=""
          class="titleIcon"
        />
      </p>
      <div class="goodsWrap">
        <div
          class="goodsCard"
          v-for="(item, index) in todaySelected"
          :key="'todaySelected' + index"
           @click="jump(item.spuId)"
        >
          <img :src="item.imgUrl" alt="" class="goodsImg" />
          <p class="goodsPrize">
            <span class="newPrize">{{detailPrice(item.salePrice,100)}}</span>
            <span class="oldPrize" v-if="!!item.originalPrice">{{
              detailPrice(item.originalPrice,100)
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="selectedWrap">
      <p class="title">
        品牌闪购
        <img
          src="@/assets/newMall/selectedTitle2.png"
          alt=""
          class="titleIcon"
        />
      </p>
      <div class="goodsWrapRight">
        <div
          class="goodsCard"
          v-for="(item, index) in brandSelected"
          :key="'brandSelected' + index"
          @click="jump(item.spuId)"
        >
          <img :src="item.imgUrl" alt="" class="goodsImg" />
          <p class="goodsPrize">
            <span class="newPrize">{{detailPrice(item.salePrice,100)}}</span>
            <span class="oldPrize" v-if="!!item.originalPrice">{{
              detailPrice(item.originalPrice,100)
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectedGoods",
  data() {
    return {
      todaySelected: [
        {
          actualPrice: "273",
          markingPrice: "350",
          spuId:'79',
          pictUrl:'http://dspcloud.spay365.com/2100ac5535a4cb021ef719710aeb6b09d0064360.jpg'
        },
        {
          actualPrice: "399",
          markingPrice: "400",
          spuId:'86',
          pictUrl:'http://dspcloud.spay365.com/8ee1d05efb196d4abed221b70c595f2e984ee7e7.jpg'
        }
      ],
      brandSelected: [
        
      ]
    };
  },
  created() {},
  beforeMount() {
    // this.getTodaySelected();
    // this.getBrandSelected();
  },
  mounted() {
    console.log('进入')
    this.getTodaySelected();
    this.getBrandSelected();
  },
  methods: {
     detailPrice(arg1,arg2) {
      console.log(arg1+'======'+arg2)
       var t1 = 0,
        t2 = 0,
        r1, r2;
        try {
          t1 = arg1.toString().split(".")[1].length
        } catch (e) {}
        try {
          t2 = arg2.toString().split(".")[1].length
        } catch (e) {}
        r1 = Number(arg1.toString().replace(".", ""));
        r2 = Number(arg2.toString().replace(".", ""));
        if (r2 == 0) {
          return Infinity;
        } else {
          return (r1 / r2) * Math.pow(10, t2 - t1);
        }
    },
    //每日精选
    getTodaySelected(){
        this.request.get("/api/banner/index/info", {
        type:4,
        count:1,
        sceneNo:this.$route.query.scence,
        channelNo:localStorage.getItem('channel')
      }).then((res) => {
        console.log(res)
        const {type,bannerProductBaseInfos} = res;
        if(type&&bannerProductBaseInfos){
          this.todaySelected = bannerProductBaseInfos;
        }
      })
    },
    //  跳转热门商品
    toLink(item) {
      //  todo 跳转链接
    },
    getBrandSelected(){
       this.request.get("/api/banner/index/info", {
        type:5,
        count:1,
        sceneNo:this.$route.query.scence,
        channelNo:localStorage.getItem('channel')
      }).then((res) => {
        console.log(res)
        const {type,bannerProductBaseInfos} = res;
        if(type&&bannerProductBaseInfos){
          this.brandSelected = bannerProductBaseInfos;
        }
      })
    },
    jump(spuId){
      this.$router.push({
        path: "/h/scm/mall/goodsDetail",
        query: {
          sceneNo:this.$route.query.scence,
          spuId: spuId,
          channel:localStorage.getItem('channel')
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.selectedGoods {
  margin: 0 auto ;
  border-radius: 0.16rem;
  background: #fff;
  width: 6.9rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  .selectedWrap {
    width: 3.2rem;
    .title {
      font-size: 0.28rem;
      font-weight: bold;
      display: flex;
      align-items: flex-start;
      padding-top: 0.25rem;
      margin-left: 0.25rem;
      padding-bottom: 0.25rem;
      line-height: 0.28rem;
      .titleIcon {
        height: 0.3rem;
        display: block;
        margin-left: 0.15rem;
      }
    }
  }
  .selectedWrap:nth-of-type(2) {
    width: 3.2rem;
    .title {
      font-size: 0.28rem;
      font-weight: bold;
      display: flex;
      align-items: flex-start;
      padding-top: 0.25rem;
      margin-right: 0.25rem;
      padding-bottom: 0.25rem;
      line-height: 0.28rem;
      .titleIcon {
        height: 0.3rem;
        display: block;
        // margin-right: 0.15rem;
        margin-left:  0.15rem;
      }
    }
  }
}
.goodsWrap {
  width: 3rem;
  margin-left: 0.25rem;
  display: flex;
  justify-content: space-between;
  .goodsCard {
    width: 1.4rem;
    position: relative;
    background: #fff;
    .goodsImg {
      display: block;
      width: 100%;
      height: 1.4rem;
      border-radius: 0.16rem;
      overflow: hidden;
    }
    .goodsPrize {
      height: 0.67rem;
      padding-top: 0.16rem;
      line-height: 0.3rem;
      font-size: 0;
      text-align: left;
      white-space: nowrap;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      span{
        display: inline-block;
      }
      .newPrize {
        font-weight: bold;
        font-size: 0.24rem;
        color: #ff3627;
        display: inline-block;
        padding-right: 0.05rem;
        text-indent: -0.2em;
        &::before {
          content: "￥";
          letter-spacing: -0.2em;
        }
      }
      .oldPrize {
        font-size: 0.2rem;
        color: #bfbfbf;
        display: inline-block;
        transform: scale(0.8);
        text-decoration: line-through;
        text-indent: -0.2em;
        transform-origin: left bottom;
        vertical-align: super;
        &::before {
          content: "￥";
          letter-spacing: -0.2em;
        }
      }
    }
  }
}
.goodsWrapRight{
  width: 3rem;
  margin-right: 0.25rem;
  display: flex;
  justify-content: space-between;
  .goodsCard {
    width: 1.4rem;
    position: relative;
    background: #fff;
    .goodsImg {
      display: block;
      width: 100%;
      height: 1.4rem;
      border-radius: 0.16rem;
      overflow: hidden;
    }
    .goodsPrize {
      height: 0.67rem;
      padding-top: 0.16rem;
      line-height: 0.3rem;
      font-size: 0;
      text-align: left;
      white-space: nowrap;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      .newPrize {
        font-weight: bold;
        font-size: 0.24rem;
        color: #ff3627;
        display: inline-block;
        padding-right: 0.05rem;
        text-indent: -0.2em;
        &::before {
          content: "￥";
          letter-spacing: -0.2em;
        }
      }
      .oldPrize {
        font-size: 0.2rem;
        color: #bfbfbf;
        display: inline-block;
        transform: scale(0.8);
        text-decoration: line-through;
        text-indent: -0.2em;
        transform-origin: left bottom;
        vertical-align: super;
        &::before {
          content: "￥";
          letter-spacing: -0.2em;
        }
      }
    }
  }
}
</style>